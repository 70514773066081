export const formCheckout = {
  shippingAddress:'Indirizzo di spedizione',
  businessAddress: "Sede amministrativa",
  legalAddress: "Sede legale",
  deliveryDateRequested:'Data consegna desiderata',
  acceptMultipleShipping:'Accetta spedizioni in acconto',
  customerOrderCode: 'Codice ordine personalizzato',
  shippingNote:'Note di spedizione',
  genericNote:'Note generiche',
  agentNote:'Note commerciale',
  offerNote:'Note Offerta',
  unconditionalDiscount: 'Sconto incondizionato',
  totalOrder:'Totale stimato ordine',
  totalOffer:'Totale stimato offerta'
};
