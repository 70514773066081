import CheckoutService from "../services/api-services/checkout-service";
import {
  getProductCartFromDto,
  buildProductDto,
  buildProductDtoComplete,
} from "@/models/product";
import i18n from "@/plugins/i18n";

const initialState = {
  order: {
    offer: {
      id: null,
      info: null,
    },
    checkingOut: false,
    itemsToCheckout: [],
  },
  offer: {
    id: null,
    checkingOut: false,
    itemsToCheckout: [],
  },
};

export const checkout = {
  state: initialState,
  actions: {
    checkoutOrder({ commit, dispatch }, form) {
      let builForm = form;
      builForm.items = form.items.map((it) => buildProductDtoComplete(it));
      return CheckoutService.postNewOrder(builForm).then(
        (response) => {
          console.log(response);
          if (response.ok) {
            commit("ClearCheckoutOrder");
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
    },

    redoOrderRequest({ commit, dispatch, getters }, id) {
      return CheckoutService.redoOrderRequest(id).then(
        (response) => {
          console.log(response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    checkoutOrderFromOffer({ commit, dispatch }, form) {
      let builForm = form;
      return CheckoutService.postNewOrderFromOffers(builForm).then(
        (response) => {
          console.log(response);
          if (response.ok) {
            commit("ClearCheckoutOrder");
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
    },
    checkoutOffer({ commit, dispatch }, form) {
      let builForm = form;
      builForm.items = form.items.map((it) => buildProductDtoComplete(it));
      return CheckoutService.postNewOffer(builForm).then(
        (response) => {
          console.log(response);
          if (response.ok) {
            commit("ClearCheckoutOffer");
            return Promise.resolve(response.data);
          }
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
    },
    async checkoutOfferAsOrder({ commit, dispatch, getters }, id) {
      try {
      let response = await CheckoutService.getSingleOffer(id);
          console.log(response);
          let offerData = response.data;
          let items = [];
          for (let rc of response.data.items) {
            let product = await dispatch("getProductAsync", rc);
            if (product.findDescriptionPerLang) {
              product.description = product.findDescriptionPerLang(
                getters.getUser.language,
                i18n.fallbackLocale
              );
            }
            product.statusLabel = i18n.t("product.statusValue." + product.status);
            items.push(product);
          }
          offerData.items = items;

          commit("checkoutOfferAsOrder", offerData);
          return offerData;
        } catch (error) {
          throw error;
        }
    },
    retryCheckoutOffer({ commit, dispatch, getters }, id) {
      console.log('retryCheckoutOffer', id);
      return CheckoutService.postRetryCheckoutOffer(id).then(
        (response) => {
          console.log(response);
        }
      );
    },
  },
  mutations: {
    checkoutOfferAsOrder(state, offer) {
      state.order.offer.id = offer.id;
      state.order.offer.info = offer;
      state.order.checkingOut = true;
      state.order.itemsToCheckout = offer.items;
    },
    StartCheckoutOrder(state, items) {
      const itemsToCheckout = items.filter((i) => i.finalUnitPrice);
      if(items.some((i) => !i.finalUnitPrice)) {
        let notes = "Si richiedono anche i seguenti prodotti senza prezzo assegnato:\n";
        notes+= items.map((i) => {
          return !i.finalUnitPrice ? i.quantity + ' x ' + i.productCode : null;
        }).filter((p) => p != null).join("\n");

        state.order.genericNote = notes;
      }
      state.order.checkingOut = true;
      state.order.itemsToCheckout = itemsToCheckout;
    },
    StartCheckoutOffer(state, items) {
      state.offer.checkingOut = true;
      state.offer.itemsToCheckout = items;
    },
    ClearCheckoutOrder(state) {
      state.order.offer = {
        id: null,
        info: null,
      };
      state.order.checkingOut = false;
      state.order.itemsToCheckout = [];
    },
    ClearCheckoutOffer(state) {
      state.offer.checkingOut = false;
      state.offer.itemsToCheckout = [];
    },
    ClearCheckoutOfferAsOrder(state, offer) {
      state.order.offer.id = null;
      state.order.offer.info = {};
      state.order.checkingOut = false;
      state.order.itemsToCheckout = [];
    },
  },

  getters: {
    getRequestOrderForOffer: (state) => state.order.offer,
    getOfferItems: (state) => state.offer.itemsToCheckout,
    getOrderItems: (state) => state.order.itemsToCheckout,
  },
};
