export const titles = {
  welcome: "Benvenuto in ADW!",
  welcomeUser: "Benvenuto {name}!",
  welcomeCustomer: "Cliente {name}",
  login: "Login",
  faq: "Video Tutorial",
  profile: "Profilo",
  profileCustomer: "Profilo cliente",
  profileUser: "Profilo utente",
  register: "Registrazione",
  forgotPassword: "Recupera password",
  newPassword: "Nuova password",
  confirmRegistration: "Conferma registrazione",
  privateArea: "Area privata",
  personalInformation: "Informazioni Personali",
  companyInformation: "Informazioni Aziendali",
  notificationEmail: "Notifiche di accesso",
  anagraphicInfo: "Informazioni anagrafiche",
  addresses: "Indirizzi",
  contacts: "Contatti",
  bankInformation: "Informazioni Bancarie",
  confirmRequest: "Conferma richiesta",
  becomeCustomerInvitation: "Vuoi diventare cliente Alcea?",
  becomeCustomer: "Diventa Cliente",
  becomeCustomerText:
    "Compila la richiesta per diventare nostri cliente, avrai accesso a tutti i prodotti dei nostri cataloghi e potrai richiedere ordini e offerte direttamente dalla tua area riservata.",
  confirmNewRequest: "Creare una nuova richiesta?",
  invitationsUser: "Inviti ricevuti per diventare cliente",
  invitationsCustomer: "Inviti inviati",
  newInvite: 'Nuovo Invito',
  invitations: 'Inviti',
  selectClient: 'Seleziona un cliente per gestire ordini ed offerte',
  newRequest: 'Richieste nuovi clienti',
  businessTypology: 'Tipologia Azienda',
  resalesInfo: 'Informazioni rivendita',
  outlook: 'Previsioni',
  areaAgent: 'I miei clienti',
  tdsApp: 'TDS',
  labApp: 'LAB',
  adminDashboardApp: 'Admin Dashboard',
  editCustomer: 'Richiesta Modifica Profilo',
  orderedProducts: 'Prodotti ordinati',
  customerRequests: "Richieste ordini / offerte",
  backToAgent: 'Cambia cliente',
  orders: 'Ordini',
  cart: 'Carrello',
  notAssigned: "Prezzo non assegnato",
  notAssignedCart: "Attenzione, il prezzo non è stato assegnato a tutti i prodotti selezionati",
  notAssignedOrder: "Attenzione, il prezzo non è stato assegnato a tutti i prodotti selezionati",
  productCodeChange: "Il prodotto ha cambiato codice",
  checkoutOrder: "Checkout richiesta ordine",
  checkoutOffer: "Checkout richiesta offerta",
  productSearch: "Ricerca prodotti",
  contactUs: "Contatti",
  loginTDSText: "Effettua il login per scaricare le TDS",
  upload: "ATTENZIONE: Caricando un nuovo file verrà sovrascritta l'alberatura della ricerca prodotti B2C, accertarsi che il proprio responsabile abbia approvato il caricamento.",
  RegistrationWithoutWS: "Registrazione senza sito web",
  startProductSearch: "Vuoi scoprire i nostri prodotti?",
  contactArea: "Customer Service",
  sdsSearch: "Ricerca SDS",
  sdsSearchWarehouse: "Ricerca per Sito",
  sdsSearchBatch: "Ricerca per Lotto",
  sdsSearchCustomer: "Ricerca per Cliente",
  sdsSearchDocument: "Ricerca per Documento",
  sdsSearchProduct: "Ricerca per Prodotto",
};
