import http from '@/util/http-common';

class ProductsSearchService {

  /** products */

  uploadProductsFile(file) {
    // prepare data
    const data = new FormData();
    data.append('file', file);

    // upload the file
    return http.post('/v1/public/b2c', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getProducts() {
    return http.get('/v1/public/b2c');
  }

  async getProductAmazonLink(productCode) {
    let url = "/v1/public/b2c/amazon-link?";
    url = this._addParam(url, 'productCode', productCode);
    return http.get(url);
  }

  searchProductsById(productClass, serial, color, pkg) {
    let url = `/products/${productClass}/${serial}/${color}/${pkg}`
    return http.get(url)
  }

  searchProductsByNameOrCode(params) {
    let url = '/products/filters/name?'
    url = this._addParam(url, 'nameCode', params.nameOrCode)
    return http.get(url)
  }

  searchProductsBySerialAndColor(params) {
    let url = '/products/filters/advanced?'
    url = this._addParam(url, 'serial', (params.serialCode ? (params.serialCode).substring(0, 4) : ''))
    url = this._addParam(url, 'color', (params.colorCode ? (params.colorCode).substring(0, 4) : ''))
    return http.get(encodeURI(url))
  }

  searchProductsByFilters(params) {
    let url = '/products/filters/multi?'
    url = this._addParam(url, 'area', params.area)
    url = this._addParam(url, 'typology', params.typology)
    url = this._addParam(url, 'family', params.family)
    url = this._addParam(url, 'dilution', params.dilution)
    url = this._addParam(url, 'drying', params.drying)
    url = this._addParam(url, 'appMethod', params.appMethod)
    return http.get(url);
  }

  /** tds */

  getTDSAvailableLanguages(params) {
    return http.get('/tds/versions/last/available-languages', {
      params: {
        serial: params.serial,
        color: params.color
      }
    })
  }

  downloadTDS(params) {
    return http.get('tds/pdf/version/' + params.id, { responseType: 'arraybuffer' })
  }

  /** sellers */

  getSellers(priv, professional) {
    return http.get('/v1/public/sellers/' + priv + '/' + professional);
  }

  /** util */

  _addParam(url, paramName, paramValue) {
    if (url.slice(-1) !== '?') {
      return url + (this.isNotEmpty(paramValue) ? "&" + paramName + "=" + paramValue : "")
    }
    return url + (this.isNotEmpty(paramValue) ? paramName + "=" + paramValue : "")
  }

  isEmpty(val) {
    return !val
  }

  isNotEmpty(val) {
    return !this.isEmpty(val)
  }

}

export default new ProductsSearchService();
