export const formCheckout = {
  shippingAddress: "Dirección de expedición",
  legalAddress: "Sede legal",
  deliveryDateRequested: "Fecha de entrega preferida",
  acceptMultipleShipping: "Acepta expediciones a cuenta",
  customerOrderCode: "Código de pedido personalizado",
  shippingNote: "Notas de expedición",
  genericNote: "Notas generales",
  agentNote:'Nota comercial',
  offerNote:'Nota de oferta',
  unconditionalDiscount: 'Sconto incondizionato',
  totalOrder: "Total estimado del pedido",
  totalOffer: "Total estimado de la oferta"
};
