export const formCheckout = {
    shippingAddress: "Lieferadresse",
    legalAddress: "Firmensitz",
    deliveryDateRequested: "Gewünschtes Lieferdatum:",
    acceptMultipleShipping: "Mehrfachlieferungen akzeptieren",
    customerOrderCode: "Kundenspezifische Bestellnummer",
    shippingNote: "Versandhinweise",
    genericNote: "Allgemeine Hinweise",
    agentNote:'Kommerzielle Notizen',
    offerNote:'Hinweise zum Angebot',
    unconditionalDiscount: 'Unbedingter Rabatt',
    totalOrder: "Geschätzter Betrag der Bestellung",
    totalOffer: "Geschätzter Betrag des Angebots"
};
