export const formCheckout = {
  shippingAddress: "Shipping address",
  legalAddress: "Registered office",
  deliveryDateRequested: "Delivery date requested",
  acceptMultipleShipping: "Accept shipments on account",
  customerOrderCode: "Custom order code",
  shippingNote: "Shipping notes",
  genericNote: "Generic notes",
  agentNote:'Commercial notes',
  offerNote:'Offer notes',
  unconditionalDiscount: 'Unconditional discount',
  totalOrder: "Estimated order total",
  totalOffer: "Estimated total offer"
};
